import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [{
    path: "",
    // 重定项
    redirect: '/welcome'
  }, {
    path: '/homeindex',
    component: () => import(
      /* webpackChunkName: "HomeIndex" */
      '../views/HomeIndex.vue'),
    children: [
      {
        path: '/welcome',
        component: () => import(
          /* webpackChunkName: "WelCome" */
          '../views/WelCome.vue')
      },
      {
        path: '/getmsg',
        component: () => import(
          /* webpackChunkName: "GetMsg" */
          '../views/GetMsg.vue')
      },
      {
        path: '/aihelp',
        component: () => import(
          /* webpackChunkName: "AIHelp" */
          '../views/AIHelp.vue')
      },
      {
        path: '/servercheck',
        component: () => import(
          /* webpackChunkName: "ServerCheck" */
          '../views/ServerCheck.vue')
      },
      {
        path: '/upandlow',
        component: () => import(
          /* webpackChunkName: "UpAndLow" */
          '../views/UpAndLow.vue')
      },
      {
        path: '/opnenewtitle',
        component: () => import(
          /* webpackChunkName: "OpenNewTitle" */
          '../views/OpenNewTitle.vue')
      },
      {
        path: '/getstartendtime',
        component: () => import(
          /* webpackChunkName: "GetStartEndTime" */
          '../views/GetStartEndTime.vue')
      },
    ]
  }, {
    path: '/login',
    component: () => import(
      /* webpackChunkName: "LoginUser" */
      '../views/LoginUser.vue'),
  }
  ]
})
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
